import React from 'react';

const TreatmentSelector = ({ treatments, onSelectTreatment }) => {
    return (
        <div className="booking-widget__container">
            {/*<h2 className="booking-widget__location-title booking_widget__inner-title">Standort wählen:</h2>*/}
            <p className="booking-widget__step_subtitle">Wählen Sie die gewünschte Leistung aus:</p>
            <div className="booking-widget__treatment-selector">
            {treatments.map((treatment, index) => (
                <div
                    key={index}
                    className="booking-widget__treatment"
                    onClick={() => onSelectTreatment(treatment)}
                >
                    <h3 className="booking-widget__treatment-title">{treatment}</h3>
                </div>
            ))}
            </div>
        </div>
    );
};

export default TreatmentSelector;