import React from 'react';

const LoadIndicator = ({ loading }) => {
    return (
        <div className={`load-indicator ${loading ? 'is-active' : ''}`}>
            <div className="load-indicator__content">
                {/* Loading content goes here, e.g., spinner or message */}
                Loading...
            </div>
        </div>
    );
};

export default LoadIndicator;