import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectDate,
    selectLocation,
    showError,
    setOptions, setLocations, fetchLocationDetails, selectTreatment
} from "../actions";
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de';
import '../sass/BookingWidget.sass';

import TimePicker from "./TimePicker";
import Form from "./Form";
import LocationSelector from "./LocationSelector";
import LoadIndicator from "./LoadIndicator";
import TreatmentSelector from "./TreatmentSelector";

const App = ({ apiUrl }) => {

    const [logoUrl, setLogoUrl] = useState('');

    const dispatch = useDispatch();
    const {
        slots,
        date,
        gui,
        settings,
        locations,
        options,
    } = useSelector(state => ({
        slots: state.dateTimeInfo.slots,
        date: state.dateTimeInfo.selectedDate,
        gui: state.gui,
        settings: state.gui.settings,
        locations: state.data.locations,
        options: state.data.options
    }));

    useEffect(() => {
        const wpbookingOptions = window.wpbookingData?.options || {};
        const wpbookingLocations = window.wpbookingData?.locations || [];
        setLogoUrl(wpbookingOptions.logo_url || '');

        if (wpbookingLocations.length === 0) {
            dispatch(showError('Keine Standorte verfügbar. Bitte versuchen Sie es später noch einmal.'));
        }

        dispatch(setOptions(wpbookingOptions));
        dispatch(setLocations(wpbookingLocations));

    }, [dispatch]);

    const handleDayClick = (day, { disabled }) => {
        if (!disabled) {
            dispatch(selectDate(day));
        }
    };

    const renderDateTimePicker = () => {
        const available = slots.filter(slot => slot.slots.length > 0).map(slot => new Date(slot.date));
        const disabled = slots.filter(slot => slot.slots.length === 0).map(slot => new Date(slot.date));


        const modifiers = {
            available: available,
            weekend: { daysOfWeek: [0, 6] },
        };


        const before = available.length > 0 ? available[0] : new Date(slots[0].date);
        const after = available.length > 0 ? available[available.length - 1] : new Date(slots[slots.length - 1].date);

        const disabledDays = disabled.length > 0 ? [
            ...disabled,
            { after: after, before: before },
        ] : [];
        return (
            <div className="booking-widget__container">
                {/*<h2 className="booking-widget__location-title booking_widget__inner-title">Standort wählen:</h2>*/}
                <p className="booking-widget__step_subtitle">Wählen Sie Datum und Uhrzeit aus:</p>
                <div className="booking-widget__date-time-picker">
                    <div className='date-time-picker'>
                        <div className={'date-picker'}>
                            <DayPicker
                                modifiers={modifiers}
                                locale={'de'}
                                localeUtils={MomentLocaleUtils}
                                onDayClick={handleDayClick}
                                selectedDays={date}
                                disabledDays={disabledDays}
                                fromMonth={before}
                                toMonth={after}
                            />
                        </div>
                        <div className={'time-picker'}>
                            <TimePicker/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderThanks = () => gui.success !== false && (
        <div className={'booking-message'}>
            <h1>Vielen Dank für Ihr Interesse</h1>
            <p>In den nächsten Minuten erhalten Sie eine Bestätigung Ihres Termins per Mail.</p>
            <p>Einige Tage vor Ihrem Beratungstermin erinnern wir Sie selbstverständlich nochmals per Mail.</p>
            <p>Wir freuen uns auf Ihren Besuch, Ihr Team von {settings.org_address_1}</p>
        </div>
    );
    const renderError = () => gui.error !== false && (
        <div className="booking-widget__root">
            <div className={'booking-widget__container'}>
                <div className={'booking-message'}>
                    <h1>Das hat leider nicht funktioniert</h1>
                    <p>{gui.errorMessage || 'Bitte versuchen Sie es später noch einmal.'}</p>
                </div>
            </div>
        </div>
    );


    if(gui.error) {
        return renderError()
    }


    if(gui.success) {
        return options.redirect_url ? null : renderThanks();
    }

    const handleLocationSelect = (location) => {
        dispatch(selectLocation(location));
        dispatch(fetchLocationDetails(location.api_url));
    };

    const handleTreatmentSelect = (treatment) => {
        dispatch(selectTreatment(treatment));
    };


    return (
        <div className="booking-widget__root">
            <div className="booking-widget__headline">
                <div className={'booking-widget__container'}>
                    <h2>Erstberatung</h2>
                </div>
            </div>
            <div className="booking-widget__content">
                <LoadIndicator loading={gui.loading}></LoadIndicator>
                <div className={'booking-widget__container'}>

                    <div className={`booking-widget ${gui.expanded ? 'expand' : ''}`}>

                        {gui.step === 0 && !gui.loading &&
                            <LocationSelector locations={locations} onSelectLocation={handleLocationSelect}/>}
                        {gui.step === 1 && !gui.loading && <TreatmentSelector treatments={gui.location.treatments}
                                                                              onSelectTreatment={handleTreatmentSelect}/>}

                        {gui.step === 2 && !gui.loading && renderDateTimePicker()}
                        {gui.step === 3 && <Form/>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default App;
