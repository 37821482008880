import { combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';

import datesReducer from "./datesReducer";
import guiReducer from "./guiReducer";
import dataReducer from "./dataReducer";

export default combineReducers({
    dateTimeInfo: datesReducer,
    gui: guiReducer,
    data: dataReducer,
    form: formReducer
});
