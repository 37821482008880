import React, {Component} from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import SelectTimeItem from "./SelectTimeItem";

class TimePicker extends Component {


    renderHeader() {
        if(this.props.date == null)
        {
            return null;
        }

        return (
            <div className={'TimePicker-Caption'}><div>{moment(this.props.date).format('DD. MMM ')}</div></div>
        );
    }

    renderTimeslots() {
        const day = this.props.slots.filter(slot => slot.date === moment(this.props.date).format('YYYY-MM-DD'));

        if(day.length === 1)
        {
            const timeSlots = day[0].slots.sort((a, b) => {
                const timeA = a.time.split(':').map(Number);
                const timeB = b.time.split(':').map(Number);
                return (timeA[0] - timeB[0]) || (timeA[1] - timeB[1]);
            });

            return timeSlots.map(slot => {
                return (
                    <SelectTimeItem key={slot.id} slot={slot}/>
                );
            });
        }
    }

    render () {
        if (this.props.date == null) return null

        return (
            <div className='TimePicker'>
                <div className="TimePicker-Caption"><div>{moment(this.props.date).format('DD. MMM ')}</div></div>
                <div className={'time-slots'}>
                    {this.renderTimeslots()}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    //console.log('date', state.dateTimeInfo.selectedDate);
    return {
        slots: state.dateTimeInfo.slots,
        date: state.dateTimeInfo.selectedDate,
    }
};

export default connect(mapStateToProps)(TimePicker);