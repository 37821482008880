export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SELECT_TREATMENT = 'SELECT_TREATMENT';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const LOCATION_DETAILS_FETCHED = 'LOCATION_DETAILS_FETCHED';
export const FETCH_SLOTS = 'FETCH_SLOTS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SELECT_DATE = 'SELECT_DATE';
export const SELECT_TIME = 'SELECT_TIME';
export const CONFIRM_TIME = 'CONFIRM_TIME';
export const SET_ROOT = 'SET_ROOT';
export const START_SUBMIT = 'START_SUBMIT';
export const SHOW_THANK_YOU = 'SHOW_THANK_YOU';
export const SHOW_ERROR = 'SHOW_ERROR'

export const SET_STEP = 'SET_STEP'

export const STORE_VALUES = 'STORE_VALUES';
