import React, {useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form'; // You'll need to install react-hook-form
import {saveAppointment} from "../actions";
import {START_SUBMIT} from "../actions/types";

const validateEmail = email => {
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(email);
};

const optionsDate = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/Berlin' };
const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Europe/Berlin' };



const Form = () => {
    const dispatch = useDispatch();
    const confirmedTime = useSelector(state => state.dateTimeInfo.confirmedTime);
    const privacyText = useSelector(state => state.gui.location.privacy); // Assuming 'location' is the state where privacy text is stored
    const location = useSelector(state => state.gui.location);
    const treatment = useSelector(state => state.gui.treatment);
    const selectedDate = useSelector(state => state.dateTimeInfo.confirmedTime.datetime);
    const { register, handleSubmit, formState: { errors }, control ,setValue} = useForm();

    const date = new Date(selectedDate);
    const formattedDate = selectedDate ? new Intl.DateTimeFormat('de-DE', optionsDate).format(date) : null;
    const formattedTime = selectedDate ? new Intl.DateTimeFormat('de-DE', optionsTime).format(date) : null;


    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async data => {
        setIsSubmitting(true);
        dispatch({type: START_SUBMIT });
        dispatch(saveAppointment(data, treatment, confirmedTime));
    };

    return (
        <div className="booking-widget__container booking-widget__last-step">
            <p className="booking-widget__step_subtitle">Bitte bestätigen Sie, dass Sie folgenden Termin buchen möchten:</p>

            <div className="booking-widget__details">
                <h3 className="booking-widget__location-title">{location.title}</h3>
                <p className="booking-widget__location-address">
                    {location.street}, {location.zip} {location.city}
                </p>
                <div className="booking-widget__location-treatments">
                    <h3 className="booking-widget__location-treatments-title">Gewünschte Beratung:</h3>
                    <ul>
                        <li>{treatment}</li>
                    </ul>
                </div>

                <p className="booking-widget__datetime">
                    {formattedDate} um {formattedTime} Uhr
                </p>
            </div>


            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className={'booking-form'}>
                    <p className="booking-widget__step_subtitle">Persönliche Daten*</p>
                    <div className="booking-form__fields">
                        <Field label='Vorname' type='text' name='firstname' register={register} errors={errors}/>
                        <Field label='Nachname' type='text' name='lastname' register={register} errors={errors} />
                        <Field label='E-Mail-Adresse' type='email' name='mail' register={register} errors={errors} validateEmail={validateEmail} />
                        <Field label='Telefonnummer' type='tel' name='phone' register={register} errors={errors} />
                    </div>


                    <p className="booking-widget__step_subtitle">Zustimmung zum Datenschutz*</p>
                    {/*<div className="field">*/}
                    {/*    <div className="control">*/}
                    {/*        <label className="checkbox">*/}
                    {/*            <input type="checkbox" {...register('datenschutz')} />*/}
                    {/*            Ich stimme den Datenschutzbestimmungen zu*/}
                    {/*        </label>*/}
                    {/*        <div dangerouslySetInnerHTML={{ __html: privacyText }} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <Field label={'Datenschutz'} type='checkbox' name='datenschutz' register={register} errors={errors} text={privacyText} />

                    <button className="button">Termin buchen</button>

                </div>
                </form>
        </div>
    );
};

const Field = ({ label, type, name, register, errors, validateEmail, text }) => {
    const validationRules = {
        required: `${label} ist erforderlich.`,
        ...(type === 'email' && { validate: value => validateEmail(value) || "E-Mail-Adresse ist ungültig." })
    };

    if(type === 'checkbox') {
        return (
            <div className={`field ${name}`}>

                <input
                    id={name}
                    className={`input css-checkbox ${errors[name] ? 'is-danger' : ''}`}
                    autoComplete="off"
                    type={type}
                    {...register(name, validationRules)}
                />
                <label className={"label"} dangerouslySetInnerHTML={{ __html: text }} htmlFor={name}/>
                {errors[name] && <p className="help is-danger">{errors[name].message}</p>}
            </div>
        );


    }

    return (
        <div className={`field ${name}`}>
            <label className="label">{label}</label>
            <div className="control has-icons-left has-icons-right">
                <input
                    placeholder={label}
                    className={`input ${errors[name] ? 'is-danger' : ''}`}
                    autoComplete="off"
                    type={type}
                    {...register(name, validationRules)}
                />
                {errors[name] && <p className="help is-danger">{errors[name].message}</p>}
            </div>
        </div>
    );
};

export default Form;
