import {SHOW_THANK_YOU} from "../actions/types";


const navigationMiddleware = history => store => next => action => {
    if (action.type === SHOW_THANK_YOU) {
        const state = store.getState();

        const location = state.gui.location;

        const locationData = {
            'name': location.title,
            'street': location.street,
            'zip': location.zip,
            'city': location.city,
            'phone': location.phone,
            'email': location.email,
        }

        const appointment = {
            'datetime': state.dateTimeInfo.confirmedTime.datetime,
            'type': state.gui.treatment
        };
        const customer = state.gui.values;

        if(state.data.options.redirect_url)
        {
            const url = state.data.options.redirect_url;
            let encodedLocation = Buffer.from(JSON.stringify(locationData)).toString("base64");
            let encodedCustomer = Buffer.from(JSON.stringify(customer)).toString("base64");
            let encodedAppointment = Buffer.from(JSON.stringify(appointment)).toString("base64");

            // // const encodedAppointment = 'test'

            window.location.href=`${url}?bi_l=${encodedLocation}&bi_a=${encodedAppointment}&bi_c=${encodedCustomer}`
            // history.push({
            //     pathname: url,
            //     search: `${url}?bi_l=${encodedLocation}&bi_a=${encodedAppointment}&bi_c=${encodedCustomer}`,
            // });
        }

    }
    return next(action);
};

export default navigationMiddleware;
