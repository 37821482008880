import React from 'react';
const LocationSelector = ({ locations, onSelectLocation }) => {
    return (
        <div className="booking-widget__container">
            {/*<h2 className="booking-widget__location-title booking_widget__inner-title">Standort wählen:</h2>*/}
            <p className="booking-widget__step_subtitle">Wählen Sie Ihren Standort aus:</p>
            <div className="booking-widget__location-selector">
                {locations.map(location => (
                    <div
                        key={location.ID}
                        className="booking-widget__location"
                        onClick={() => onSelectLocation(location)}
                    >
                        <h3 className="booking-widget__location-title">{location.title}</h3>
                        <p className="booking-widget__location-address">
                            {location.street}, {location.zip} {location.city}
                        </p>
                        {/*<p className="booking-widget__location-contact">*/}
                        {/*    Tel: {location.phone} | Email: {location.email}*/}
                        {/*</p>*/}
                        <div className="booking-widget__location-treatments">
                            <h3>Verfügbare Beratungen:</h3>
                            <ul>
                                {location.treatments.map((treatment, index) => (
                                    <li key={index}>{treatment}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LocationSelector;