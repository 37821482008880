import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

import App from "./components/App";
import reducers from './reducers';
import navigationMiddleware from "./middlewares/navigationMiddleware";


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers, // root reducer with router state
    composeEnhancers(
        applyMiddleware(
            thunk, // for async actions
            navigationMiddleware(history) // your custom middleware
        )
    )
);

const rootElement = document.getElementById('booking-app');

if (rootElement) {
    const apiUrl = rootElement.dataset.apiUrl;
    const logo = rootElement.dataset.logo;

    ReactDOM.render(
        <Provider store={store}>
            <App apiUrl={apiUrl} logoUrl={logo}/>
        </Provider>,
        rootElement
    );
}

